import classNames from 'classnames';
import React from 'react';
import './richText.scss';

const richTextClasses = classNames(
    'eds-g-cell',
    'eds-g-cell-12-12',
    'eds-g-offset-0-12',
    'eds-g-cell-md-8-12',
    'eds-g-offset-md-2-12',
    'eds-g-cell-lg-8-12',
    'eds-g-offset-lg-2-12',
    'eds-text--left',
);

interface RichTextProps {
    richText: string;
    moduleClass: string;
}
const RichTextModule = ({ richText, moduleClass }: RichTextProps) => (
    <section className={`rich-text-module ${moduleClass}`}>
        <div className="marketing-container__max-width eds-l-pad-hor-6">
            <div className="eds-g-grid">
                <div className="mktg-top-p-120">
                    <div
                        className={richTextClasses}
                        //eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: richText }}
                    ></div>
                </div>
            </div>
        </div>
    </section>
);
export default RichTextModule;
